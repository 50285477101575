import React, {PropsWithChildren, useContext} from 'react';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import {Nav} from "../components/nav";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import {ChangeCircle, ChevronLeft, LogoutRounded} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import MenuIcon from '@mui/icons-material/Menu';
import {CanaryService, ChangeService, ProductionService} from "../components/service/Service";
import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {Title} from "../components/title";
import { useAuth0 } from '@auth0/auth0-react';
import Typography from "@mui/material/Typography";
import AuthenticatedAppContext from "../components/authenticate/AuthenticatedAppContext";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export const WithSidebar = ({ children, titlePattern }: PropsWithChildren & { titlePattern: string }): React.JSX.Element => {

    const {logout} = useAuth0();
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const authenticatedApp = useContext(AuthenticatedAppContext);
    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton edge="start"
                        color="inherit"
                        aria-label="logout"
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                        onClick={() => logout()}>
                        <LogoutRounded />
                    </IconButton>
                    <Title titlePattern={titlePattern} />
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeft />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <Nav />
                </List>
                <Divider />
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <ProductionService />
                        </ListItemIcon>
                        <ListItemText primary="Production Service" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CanaryService />
                        </ListItemIcon>
                        <ListItemText primary="Canary Service" />
                    </ListItem>
                </List>
                <Divider />
                { authenticatedApp?.userPerms?.includes('deployment:admin') && (
                    <ChangeService />
                )}

            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) => theme.palette.grey[100],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                    pt: 10,
                    pb: 2,
                }}
            >
                {children}
            </Box>
        </Box>
    )
}