import {Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Box from "@mui/material/Box";
import {Service} from "../../service";
import {HostnamesAsLinks} from "../hostnames";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import {IngressSummary} from "../ingresses";

interface FullProps {
    ingressesData: Array<IngressSummary>
}
export const Full = (props: FullProps): React.JSX.Element => {
    const {
        ingressesData
    } = props;
    return (
        <TableContainer>
            <Table sx={{ minWidth: 650, maxWidth: 850, margin: "0 auto", background: "white" }} aria-label="ingresses">
                <TableHead>
                    <TableRow>
                        <TableCell>Service / ID</TableCell>
                        <TableCell>Hostnames</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Configure</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ingressesData.map(ing => (
                        <TableRow
                            key={ing.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                <Box display="flex">
                                    <Box mr={1}>
                                        <Service service={ing.service} />
                                    </Box>
                                    <Box>
                                        {ing.id}
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell><HostnamesAsLinks hostnames={ing.hostnames} /></TableCell>
                            <TableCell>{ing.created}</TableCell>
                            <TableCell align="center">
                                <Tooltip title={`Configure ${ing.id}`}>
                                    <Link href={`/sites/${ing.id}`}>
                                        <IconButton color="primary" aria-label="configure site" component="label">
                                            <SettingsIcon />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}