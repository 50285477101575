import {LoaderFunctionArgs} from "react-router";
import React, {useState} from "react";
import {useLoaderData} from "react-router-dom";
import {LoaderData} from "../../types";
import {ServiceName} from "../service/Service";
import {CircularProgress, Fab, ToggleButton, ToggleButtonGroup} from "@mui/material";
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { authenticatedRequest } from "../authenticate/authenticatedRequest";
import { Add } from "@mui/icons-material";
import {Full, Tabbed} from "./site-list";

export interface IngressSummary {
    id: string;
    created: string;
    service: ServiceName,
    hostnames: string[],
}

export async function ingresses({ params }: LoaderFunctionArgs): Promise<IngressSummary[]> {
    return await authenticatedRequest(`${process.env.REACT_APP_PROXY_SERVER}/websites`)
        .then(r => r.json());
}
type ChangeHandler = (event: React.MouseEvent<HTMLElement>, newValue: LayoutTypes) => void
interface VerticalToggleButtonProps {
    view: LayoutTypes,
    handleChange: ChangeHandler
}
const VerticalToggleButtons = (props: VerticalToggleButtonProps): React.JSX.Element => {
    const {
        view,
        handleChange
    } = props;

    return (
        <ToggleButtonGroup
            orientation="vertical"
            value={view}
            exclusive
            onChange={handleChange}
        >
            <ToggleButton value="list" aria-label="list">
                <ViewListIcon />
            </ToggleButton>
            <ToggleButton value="tabbed" aria-label="tabbed">
                <ViewModuleIcon />
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

type LayoutTypes = "tabbed" | "list";
export const Ingresses = (): React.JSX.Element => {

    const [layout, setLayout] = React.useState<LayoutTypes>("tabbed");
    const ingressesData = useLoaderData() as LoaderData<typeof ingresses>;
    if(!ingressesData) {
        return <CircularProgress color="primary"/>
    }
    const output = layout === "tabbed" ? <Tabbed ingressesData={ingressesData} /> : <Full ingressesData={ingressesData} />;

    return (
        <>
            <div style={{ position: "absolute", left: "95%", bottom: "25px" }}>
                <VerticalToggleButtons view={layout} handleChange={(event, newValue: LayoutTypes) => {
                    setLayout(newValue)
                }}/>
            </div>
            <Fab  sx={{ position: "absolute", left: "90%", bottom: "25px" }} className="contrast" color="primary" aria-label="add" href="/sites/create">
                <Add />
            </Fab>
            {output}
        </>
    );
}