import React from "react";
import { ProtectedRouteProps } from "../../types";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { WithSidebar } from "../../layouts";


export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const {
      component,
      titlePattern,
      args,
  } = props;
  const ProtectedComponent = withAuthenticationRequired(component, args);
  return (
    <WithSidebar titlePattern={titlePattern}>
      <ProtectedComponent />
    </WithSidebar>
  )
}