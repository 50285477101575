import React from "react";
import {
    Link,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import {Service} from "../../service";
import {HostnamesAsLinks} from "../hostnames";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";

import {IngressSummary} from "../ingresses";
import Typography from "@mui/material/Typography";

interface TabbedProps {
    ingressesData: Array<IngressSummary>
}
interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps): React.JSX.Element => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const BlueServiceSiteList = (props: TabbedProps): React.JSX.Element => {
    const {
        ingressesData
    } = props;
    return (
        <TableContainer>
            <Table sx={{ minWidth: 650, margin: "0 auto", background: "white" }} aria-label="ingresses">
                <TableHead>
                    <TableRow>
                        <TableCell>Service / ID</TableCell>
                        <TableCell>Hostnames</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Configure</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ingressesData.filter(ing => ing.service === 'blue').map(ing => (
                        <TableRow
                            key={ing.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                <Box display="flex">
                                    <Box mr={1}>
                                        <Service service={ing.service} />
                                    </Box>
                                    <Box>
                                        {ing.id}
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell><HostnamesAsLinks hostnames={ing.hostnames} /></TableCell>
                            <TableCell>{ing.created}</TableCell>
                            <TableCell align="center">
                                <Tooltip title={`Configure ${ing.id}`}>
                                    <Link href={`/sites/${ing.id}`}>
                                        <IconButton color="primary" aria-label="configure site" component="label">
                                            <SettingsIcon />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
const GreenServiceSiteList = (props: TabbedProps): React.JSX.Element => {
    const {
        ingressesData
    } = props;
    return (
        <TableContainer>
            <Table sx={{ minWidth: 650, margin: "0 auto", background: "white" }} aria-label="ingresses">
                <TableHead>
                    <TableRow>
                        <TableCell>Service / ID</TableCell>
                        <TableCell>Hostnames</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Configure</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ingressesData.filter(ing => ing.service === 'green').map(ing => (
                        <TableRow
                            key={ing.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                <Box display="flex">
                                    <Box mr={1}>
                                        <Service service={ing.service} />
                                    </Box>
                                    <Box>
                                        {ing.id}
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell><HostnamesAsLinks hostnames={ing.hostnames} /></TableCell>
                            <TableCell>{ing.created}</TableCell>
                            <TableCell align="center">
                                <Tooltip title={`Configure ${ing.id}`}>
                                    <Link href={`/sites/${ing.id}`}>
                                        <IconButton color="primary" aria-label="configure site" component="label">
                                            <SettingsIcon />
                                        </IconButton>
                                    </Link>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export const Tabbed = (props: TabbedProps): React.JSX.Element => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Box style={{margin: '0 auto', maxWidth: 850, minWidth: 650, display: 'flex', flexDirection: 'column'}}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="inherit"
                variant="fullWidth"
            >
                <Tab label="Blue Service" key="blue-service" />
                <Tab label="Green Service" key="green-service" />
            </Tabs>
            <TabPanel value={value} index={0} dir={theme.direction}>
                <BlueServiceSiteList {...props} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                <GreenServiceSiteList {...props} />
            </TabPanel>

        </Box>
    );
}